/* Common Imports */

import { styled } from "@mui/system";
import React from "react";

/* Redux Imports */

import { ObjectId } from "mongodb";
import StateInterface from "../../../redux-magic/state-interface";
import { ServicePrice } from "../../../redux-magic/sub-interfaces/sub-interfaces";

/* Component Imports */

import { Chip, Link, useTheme } from "@mui/material";
import ServiceCardImage from "./sub-components/ServiceCardImage";
import ServiceCardLocation from "./sub-components/ServiceCardLocation";
import ServiceCardPrice from "./sub-components/ServiceCardPrice";
import ServiceCardProviderAndContact from "./sub-components/ServiceCardProviderAndContact";
import ServiceCardSubType from "./sub-components/ServiceCardSubType";
import ServiceCardTitle from "./sub-components/ServiceCardTitle";

/* Styled Components */

const ServiceLink = styled(Link)(({ theme }) => ({
	textDecoration: "none",
	color: theme.palette.mode == "dark" ? "#fff" : "#000",
	"&:hover": {
		textDecoration: "none",
	},
}));

const ServiceCardContainer = styled("div", {
	shouldForwardProp: (prop) => prop !== "source",
})<{ source: string }>(({ theme, source }) => ({
	background: theme.palette.background.paper,
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "center",
	borderRadius: "16px",
	objectFit: "contain",
	width: source === "search" ? "100%" : "22.05rem",
	margin: "0.1rem",
	// boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.12)",
	scrollSnapAlign: "start",
	/* XXS breakpoint */
	[theme.breakpoints.up("xxs")]: {
		width: source === "search" ? "16rem" : "18rem",
		height: "100%",
	},
	/* iphone 5 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 39)]: {
		width: source === "search" ? "17.75rem" : "18.05rem",
		height: "100%",
	},
	/*galaxy S8+ - 360*/
	[theme.breakpoints.up(theme.breakpoints.values.xs + 78)]: {
		width: source === "search" ? "20.25rem" : "20.5rem",
		height: "100%",
	},
	/*iphone se - 375*/
	[theme.breakpoints.up(theme.breakpoints.values.xs + 94)]: {
		width: source === "search" ? "21.25rem" : "21.25rem",
		height: "100%",
	},
	/* iphone 12 pro and pixel - 390 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 108.95)]: {
		width: source === "search" ? "22rem" : "22.05rem",
		height: "100%",
	},
	/*iphone XR - 414*/
	[theme.breakpoints.up(theme.breakpoints.values.xs + 130)]: {
		width: source === "search" ? "23.5rem" : "22.05rem",
		height: "25.875rem",
	},
	/*iphone 14 pro max - 430*/
	[theme.breakpoints.up(theme.breakpoints.values.xs + 149)]: {
		width: source === "search" ? "24.5rem" : "22.05rem",
		height: "25.875rem",
	},
	[theme.breakpoints.up("xsPlus")]: {
		width: source === "search" ? "30.5rem" : "22.05rem",
		height: "25.875rem",
	},
	[theme.breakpoints.up("sm")]: {
		width: "22.05rem",
		height: "25.875rem",
	},
	/*ipad Mini */
	[theme.breakpoints.up(theme.breakpoints.values.sm + 167)]: {
		width: "22.05rem",
		height: "25.875rem",
	},
	/* ipad Air*/
	[theme.breakpoints.up(theme.breakpoints.values.sm + 219)]: {
		width: "22.05rem",
		height: "25.875rem",
	},
	[theme.breakpoints.up("md")]: {
		width: "22.05rem",
		height: "25.875rem",
	},
	/* MDLG Breakpoint iPadPro*/
	[theme.breakpoints.up(theme.breakpoints.values.md + 64)]: {
		width: "22.05rem",
		height: "25.875rem",
	},
	/*720p and 768p breakpoint */
	[theme.breakpoints.up("lg")]: {
		width: "22.05rem",
		height: "25.875rem",
	},
	/* 1080p 125% breakpoint*/
	[theme.breakpoints.up(theme.breakpoints.values.lg + 150)]: {
		width: "22.05rem",
		height: "25.875rem",
	},
	/* 1080p breakpoint*/
	[theme.breakpoints.up("xl")]: {
		width: "22.05rem",
		height: "25.875rem",
	},
	/* XXL breakpoint  2560p*/
	[theme.breakpoints.up(theme.breakpoints.values.xl + 640)]: {
		width: "22.05rem",
		height: "25.875rem",
	},
	/*4k breakpoint 3840p*/
	[theme.breakpoints.up(theme.breakpoints.values.xl + 1920)]: {
		width: "22.05rem",
		height: "25.875rem",
	},
}));

const ServiceCardInfoContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	padding: "0.75rem 1.25rem 0rem 1.25rem",
	margin: "0rem 0rem 0rem 0rem",
	width: "100%",
	height: "100%",
	borderRadius: "0px 0px 16px 16px",
	cursor: "pointer",
	[theme.breakpoints.down(413)]: {
		padding: "0.75rem 1.25rem 1rem 1.25rem",
	},
}));

const TitleContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "space-between",
	padding: "1rem 1.25rem 0rem 1rem",
	width: "100%",
}));

const PriceAndTransactionContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
}));

const CustomChip = styled(Chip)(({ theme }) => ({
	height: "1.75rem",
	// fontWeight: 500,
	fontSize: "0.875rem",
	letterSpacing: "0.02857em",
	borderRadius: "8px",
	backgroundColor: "#fff6bd",
	color: "#212121",
}));

const CardRow = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	marginBottom: "1rem",
	width: "100%",
}));

const VerticalLine = styled("div")(({ theme }) => ({
	borderLeft: "1px solid #D9D9D9",
	height: "2rem",
}));

const ServiceCardGridView = ({
	profile_context,
	session,
	dispatch,
	loading,
	_id,
	slug,
	title,
	images,
	location,
	price,
	serviceSubtype,
	service_provider_name,
	saved_content,
	created_by,
	boosting,
	show_contact_details,
	source,
}: {
	profile_context: StateInterface["profile_context"];
	session: any;
	dispatch: Function;
	loading: boolean;
	_id: ObjectId | string;
	slug: string;
	title: string;
	images: string;
	location: string;
	price: ServicePrice;
	serviceSubtype: Array<string>;
	service_provider_name: string;
	saved_content: Array<any>;
	created_by?: any;
	assigned_to?: StateInterface["single_business_profile"]["business_profile"]["assigned_to"];
	isEditor?: boolean;
	boosting: StateInterface["property"]["boost_information"];
	show_contact_details: boolean;
	source: string;
}) => {
	const theme = useTheme();

	let BoostingCheck = boosting?.active ? true : false;

	return (
		<React.Fragment>
			<ServiceLink
				href={`/services/${slug}`}
				target="_self"
				referrerPolicy="no-referrer"
				title={title}
			>
				<ServiceCardContainer source={source ? source : ""}>
					{/* Image */}

					<ServiceCardImage
						_id={_id}
						images={images}
						dispatch={dispatch}
						session={session}
						profile_context={profile_context}
						loading={loading}
						saved_content={saved_content}
						boosting={boosting}
					/>

					<TitleContainer
						sx={{
							// borderBottom: BoostingCheck ? "3px solid #fdb375" : "none",
							borderRight: BoostingCheck ? "3px solid #fdb375" : "none",
							borderLeft: BoostingCheck ? "3px solid #fdb375" : "none",
							background: BoostingCheck ? "#fc80190d" : theme.palette.background.paper,
						}}
					>
						<PriceAndTransactionContainer>
							{/* Price */}

							<ServiceCardPrice
								loading={loading}
								price={price}
							/>

							{/* Transaction Type Chip */}

							<CustomChip label="Service" />
						</PriceAndTransactionContainer>

						{/* Title */}

						<ServiceCardTitle
							loading={loading}
							title={title !== "" || undefined ? title : "Unknown Title"}
						/>
					</TitleContainer>

					<ServiceCardInfoContainer>
						<CardRow>
							{/* Subtype */}

							<ServiceCardSubType
								loading={loading}
								serviceSubtype={serviceSubtype}
							/>

							<VerticalLine />

							{/* Location */}

							<ServiceCardLocation
								loading={loading}
								location={location !== "" || undefined ? location : ""}
							/>
						</CardRow>

						{/* Service Professional and CTA */}

						<ServiceCardProviderAndContact
							loading={loading}
							service_provider_name={service_provider_name !== "" || undefined ? service_provider_name : "Unknown"}
							created_by={created_by}
							dispatch={dispatch}
							session={session}
							slug={slug}
							title={title}
							show_contact_details={show_contact_details}
						/>
					</ServiceCardInfoContainer>
				</ServiceCardContainer>
			</ServiceLink>
		</React.Fragment>
	);
};

export default ServiceCardGridView;
